<template>
  <el-container>
    <el-header>
      员工信息
    </el-header>
    <el-main class="main">

      <div class="content">
        <div class="staff-info">
          <!-- <div class="cell-s" @click="jumpStaffDetail">
            <div class="title">
              <img src="@/assets/images/avatar.png" alt="">
              <div>
                <div class="name">顾小敏</div>
                <div class="tel">1588588585</div>
              </div>
            </div>
            <div class="value">
              管理员
            </div>
          </div> -->
          <div class="cell-s" v-for="(item,i) in storeList " :key="i" @click="jumpStaffDetail(item)">
            <div class="title">
              <img :src="$getimgsrc(item.avatar)" alt="">
              <div>
                <div class="name">{{ item.realname}}</div>
                <div class="tel">{{ item.mobile }}</div>
              </div>
            </div>
            <div class="value">
              {{ item.manypersonrole_id }}
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  export default {

    data() {
      return {
        storeList:[]
      }
    },
    mounted(){
      this.getList()
    },
    methods: {
      //员工列表
      getList(){
        this.$http.post('api/store/storeset/storeperson').then(res =>{
          this.storeList = res.data.data
        })
      },
      //员工详情
      jumpStaffDetail(item) {
        this.$store.commit('setStaff',item)
        this.$router.push({
          name: 'StaffDetail',
        })
      },
    }
  }

</script>

<style lang="less" scoped>
  .el-header {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
  }

  .main {
    background-color: #f9f9f9;
  }

  .content {
    padding: 20px;

    .staff-info {
      .cell-s {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background-color: #fff;
        color: #666;
        margin-bottom: 15px;
        box-shadow: 0 0 2px #ccc;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 50%;
          }

          .name,
          .tel {
            font-size: 12px;
            line-height: 20px;
          }
        }

        .value {
          width: 100px;
          line-height: 30px;
          text-align: center;
          border: 1px solid #999;
          border-radius: 5px;
        }
      }
    }

  }

</style>
